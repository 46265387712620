//import Vue from "vue";
import { createRouter, createWebHistory } from "vue-router";

import Login from "@/components/Login";
import Home from "@/views/Home";
import auth from "@/store/modules/auth";

const router = createRouter({
  history: createWebHistory(), //vue2 为mode
  routes: [
    {
      path: "/first",
      name: "index",
      component: () => import(/*first*/ "../views/First.vue"),
    },

    {
      path: "/login",
      name: "Login",
      component: Login,
    },

    {
      path: "/",
      name: "home",
      component: Home, // ()=> import(/*home*/"../components/Home.vue" )
      meta: {
        requiresAuth: true,
      },

      children: [
        {
          name: "sample",
          path: "sample",
          component: () => import(/*sample*/ "@/views/Sample.vue"),
        },

        {
          name: "samplelist",
          path: "samplelist/:page",
          component: () => import(/*samplelist*/ "@/components/SampleList.vue"),
          props: true,
        },
        {
          name: "tasklist",
          path: "tasklist",
          component: () => import(/*tasklist*/ "@/views/TaskList.vue"),

        },
        {
          name: "taskfilterdate",
          path: "taskfilterdate",
          component: () => import(/*taskfilterdate*/ "@/views/TaskFilterDate.vue"),
        },
        {
          name: "taskfilterlabchargeid",
          path: "taskfilterlabchargeid",
          component: () => import(/*taskfiltersamplecode*/ "@/views/TaskFilterLabchargeid.vue"),
        },
        {
          name: "taskfiltersamplecode",
          path: "taskfiltersamplecode",
          component: () => import(/*taskfiltersamplecode*/ "@/views/TaskFilterSamplecode.vue"),
        },
        {
          name: "labchargetask",
          path: "labchargetask",
          component: () => import(/*labchargetask*/ "@/views/LabChargeTask.vue"),
        },
        {
          name: "approvetask",
          path: "approvetask",
          component: () => import(/*approvetask*/ "@/views/ApproveTask.vue"),
        },
        {
          name: "publishtask",
          path: "publishtask",
          component: () => import(/*publishtask*/ "@/views/PublishTask.vue"),
        },
        {
          name: "topreport",
          path: "topreport",
          component: () => import(/*topreport*/ "@/views/TopReport.vue"),
        },
        {
          path: "/helpdoc",
          name: "helpdoc",
          component: () => import(/*helpdoc*/ "@/views/HelpDoc.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/sysadmin",
          name: "sysadmin",
          component: () => import(/*uploadtemplate*/ "@/views/UploadTemplate.vue"),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "/excelcontainer",
      name: "excelcontainer",
      component: () => import(/*excel*/ "@/views/ExcelContainer.vue"),
      //component: ()=> import(/*account*/"@/views/WebWps.vue" ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/wfile",
      name: "wfile",
      component: () => import(/*wfile*/ "../components/Wfile.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/oneadmin",
      name: "oneadmin",
      component: () => import(/*oneadmin*/ "@/views/OneAdmin.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/outdooradmin",
      name: "outdooradmin",
      component: () => import(/*outdooradmin*/ "@/views/OutdoorAdmin.vue"),
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to,from,next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (auth.state.isAuthenticated) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
