import {USER_REQUEST,USER_ERROR,USER_SUCCESS} from  "../actions/user";
//import Vue from "vue";
import {AUTH_LOGOUT} from "../actions/auth";


const state ={
    status:"",
    profile:{}
};

const getters={
    getProfile:state=>state.profile,
    isProfileLoaded:state=>!!state.profile.name
};

const actions={
    [USER_REQUEST]:({commit})=>{
        return new Promise((resolve,reject)=>
        {
            commit(USER_REQUEST);
            fetch('https://jsonplaceholder.typicode.com/posts/1')
            .then(response => response.json())
            .then(json => {
                console.log(json);
                console.log("user");
                commit(USER_SUCCESS,{name:"cheng"});
                resolve();
            })
            .catch(error=>{              
                reject(error.message);
            })
           
        })
      
    }
};


const mutations= {
    [USER_REQUEST]:state=>{
        state.status = "loading"
    },
    [USER_SUCCESS]:(state,res)=>{
        state.status = "success";
        console.log(res)
        state.user.profile=res;
        //Vue.set(state,"profile",res);
    },
    [USER_ERROR]:state=>{
        state.status = "error";
    },
    [AUTH_LOGOUT]:state=>{
        state.profile={}
    }
};


export default{
    //namespaced: true,
    state,
    getters,
    actions,
    mutations
};