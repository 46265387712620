<template>
  <div class="container1">
    <div class="header">
      <ul>
        <li>
          <button type="button" class="btn btn-primary" @click="callwps">
            ①检测wps状态
          </button>
        </li>
        <li>
          <button type="button" class="btn btn-primary" @click="deployaddons">
            ②与wps握手
          </button>
        </li>
        <li>
          <button type="button" class="btn btn-primary" @click="openwps">
            模拟测试打开wps+登录
          </button>
        </li>
        <li>
          左边①②功能仅在首次连接wps使用
        </li>

        <li>
          <button class="btn btn-danger" @click="logout">退出</button>
        </li>
      </ul>
    </div>
    <div class="side">
      <router-link :to="{ name: 'sample' }">报告作业(主)</router-link>
      <router-link :to="{ name: 'tasklist' }">全部任务</router-link>
      <router-link :to="{ name: 'taskfilterdate' }">按日期筛查</router-link>
      <router-link :to="{ name: 'taskfilterlabchargeid' }">按检测人筛查</router-link>
      <router-link :to="{ name: 'taskfiltersamplecode' }">按代码筛查</router-link>
      <router-link :to="{ name: 'labchargetask' }">1检测任务</router-link>
      <router-link :to="{ name: 'approvetask' }">2审核报告</router-link>
      <router-link :to="{ name: 'publishtask' }">3批准报告</router-link>
      <router-link :to="{ name: 'topreport' }">最新提交报告</router-link>
      <router-link :to="{ name: 'helpdoc' }">软件下载</router-link>
      <router-link :to="{ name: 'samplelist', params: { page: 1 } }"
        >任务单列表</router-link
      >
    </div>
    <div class="main">
      <!-- <keep-alive  max="3">
        </keep-alive> -->
      <!-- <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" :key="$route.fullPath" />
        </keep-alive>
      </router-view> -->
      <router-view />
    </div>
    <div class="footer">copyright ©2021 广西交航</div>
  </div>
</template>

<script>
import { Base64 } from "js-base64";
import { oaUrl } from "@/config.js";
export default {
  name: "home",
  components: {
    //Sample,
  },
  methods: {
    logout: function() {
      this.$store.dispatch("auth/AUTH_LOGOUT").then(() => {
        this.$router.push("/login");
      });
    },
    // 调起wps
    callwps() {
      // window.location.href = "ksoWPSCloudSvr://start=RelayHttpServer"
      window.open("ksoWPSCloudSvr://start=RelayHttpServer", "_blank");
    },
    // 配置助手
    async deployaddons() {
      //"{"cmd":"enable/disable/disableall"","name":"wpsaddin/wpsaddin","url":"http://oa.bitbridge.top/","addonType":"et","online":"true"}"
      const data = {
        cmd: "enable",
        addonType: "et",
        name: "oa",
        url: oaUrl,
        online: "true",
      };
      const res = await fetch("http://localhost:58890/deployaddons/runParams", {
        method: "POST",
        body: Base64.encode(JSON.stringify(data)),
      });
      if (res.ok) {
        alert("配置成功");
      }
    },
    // 检查是否在线
    async isOnline() {
      const data1 = {
        method: "get",
        url: `${oaUrl}/ribbon.xml`,
        data: "",
      };
      //http://127.0.0.1:58890/publishlist 配置助手列表
      const res = await fetch("http://localhost:58890/redirect/runParams", {
        method: "POST",
        body: Base64.encode(JSON.stringify(data1)),
      });
      if (res.ok) {
        const res2 = await res.text();
        const result = res2.startsWith("<customUI");
        if (result) {
          alert("在线中");
        }
      }
    },

    async openwps() {
      //step1
      const res1 = await fetch("http://127.0.0.1:58890/version", {
        method: "POST",
        body: "",
      });
      console.log(res1.ok); //结果为true,返回json响应,但是响应为1.0.0,无法转化为json,无法转化为 res.json()

      // 分支1
      if (!res1.ok) {
        const oafun = { testwps: { msg: "绿城南宁" } };
        const startInfo = {
          name: "oa",
          function: "dispatcher",
          info: JSON.stringify({ funcs: [oafun] }),
          jsPluginsXml: `${oaUrl}/jsplugins.xml`,
        };
        const strData = JSON.stringify(startInfo);
        const baseData = Base64.encode(strData);
        const data = "ksowebstartupet://" + baseData;
        const res = await fetch("http://127.0.0.1:58890/et/runParams", {
          method: "POST",
          body: data,
        });
        console.log(res);

        // 分支2
      } else {
        const userStr = JSON.stringify(this.$store.state.auth.user);
        const oafun = {
          testwps: {
            msg: "绿城南宁",
            token: this.$store.state.auth.token,
            user: userStr,
          },
        };
        // 发wps server 发送助手要调用的函数
        const cmdId = `js${new Date().getTime()}_0`;
        const url = "http://127.0.0.1:58890/transferEcho/runParams";
        const funcEx = "const res = dispatcher";
        const infocontent = JSON.stringify({ funcs: [oafun] });
        // 官方写法 const cbCode = `var xhr = new XMLHttpRequest();xhr.open('POST','${url}');xhr.send(JSON.stringify({id: '${cmdId}', response: res}));`;
        const cbCode = `fetch("${url}", {method: "POST",body: JSON.stringify({id: '${cmdId}', response: res})});`;
        const infoEx = `${infocontent});${cbCode}void(0`;
        const startInfo = {
          name: "oa",
          function: funcEx,
          info: infoEx,
          jsPluginsXml: `${oaUrl}/jsplugins.xml`,
        };
        const baseData = Base64.encode(JSON.stringify(startInfo));
        // 向wps server发请求
        const wraper = {
          id: cmdId,
          app: "et",
          data: `ksowebstartupet://${baseData}`,
        };
        const res = await fetch("http://127.0.0.1:58890/transfer/runParams", {
          method: "POST",
          body: JSON.stringify(wraper),
        });
        console.log(res);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container1 {
  display: grid;
  grid-template-columns: minmax(120px, 0.1fr) 1fr;
  grid-gap: 1rem;
  grid-template-rows: minmax(40px, auto) auto minmax(50px, auto);
  box-sizing: border-box;
}
.container1 > div {
  border: 1px black solid;
  border-radius: 4px;
}
.header {
  grid-column: 1/3;
}
.main {
  min-height: 700px;
  padding: 1rem;
}
.footer {
  grid-column: 1/3;
  text-align: center;
}
.nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.side a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.side a:hover {
  background-color: #ccc;
}

.side a.active {
  background-color: #4caf50;
  color: white;
}

.header ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-top: 10px;
}
.header ul li {
  display: block;
  margin-right: 20px;
}
.header ul li:last-child {
  margin-left: auto;
}
</style>
