//import Vue from 'vue'

import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import store from "./store"
import * as WPS from './web-office-sdk-v1.1.8.es'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import ToastService from 'primevue/toastservice';





const vm= createApp(App);
vm.use(router)
vm.use(store)
vm.use(PrimeVue)
vm.use(ConfirmationService);
vm.use(ToastService);

//vm.prototype.wps = WPS; //vue2
vm.config.globalProperties.wps = WPS //vue3
vm.mount('#app')
