//import Vue from "vue";
//import Vuex from "vuex";
import { createStore } from 'vuex'
import user from "./modules/user";
import auth from "./modules/auth";
import sample from "./modules/sample";
import task from "./modules/task";
//Vue.use(Vuex);



export default createStore({
  modules: {
    user,
    auth,
    sample,
    task,
  //  strict: true
  }
});