export const baseUrl = "https://api.bitbridge.top";  //http://api.csmicrobit.club:6080  wps配置http://api.csmicrobit.club:6080/wps
export const oaUrl = "https://oa.bitbridge.top";   //https://oa.bitbridge.top
export const oneAdminUrl = "http://oneadmin.bitbridge.top"; //http://oneadmin.bitbridge.top
export const outdoorUrl = "http://8.135.14.47:5006"
//export const jsplugins = `${oaUrl}/jsplugins.xml`;
export const bucketUrl = "https://chengoss.oss-cn-shenzhen.aliyuncs.com";
export const oss_access_key = "LTAI4G118LHKH6SFgujvgtPL";
export const statusflags =  [
    { id: 0, name: "待发放" },
  // { id: 1, name: "复核" },
    { id: 2, name: "退回" },
    { id: 3, name: "已发放" },
    { id: 4, name: "已接收" },
    { id: 5, name: "提交实验数据" },
    { id: 6, name: "中间报告" },
    { id: 7, name: "报告已批准" },
    { id: 8, name: "提交档案" },
    { id: 9, name: "档案员已接收" },
    { id: 10, name: "档案员已提交前台(归档)" },
    { id: 11, name: "前台接收报告" },
    { id: 12, name: "前台发放报告" },
    { id: -1, name: "作废" },
  ];

  export const reportFlags =  [
    { id: 0, name: "已发放" },
    { id: 1, name: "已接收" },
    { id: 2, name: "中间报告退回" },
    { id: 3, name: "中间报告提交" },
    { id: 4, name: "中间报告审核" },
    { id: 5, name: "中间报告发布" },
    { id: 6, name: "报告退回" },
    { id: 7, name: "提交" },
    { id: 8, name: "审核" },
    { id: 9, name: "批准" },
    { id: 10, name: "完结" },
    { id: -1, name: "作废" },
    { id: -2, name: "前台撒回" },
  ];
