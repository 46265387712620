import { baseUrl } from "@/config.js";
import auth from "./auth"

const state={
    samples:[],
    sampleTotal:0,
    sample:{},
    perPage:10,    
};

const mutations ={
    ADD_SAMPLE(state,sample){
        state.samples.push(sample);
    },
    SET_SAMPLES(state,samples){
        state.samples=samples;
    },
    SET_SAMPLETOTAL(state,sampleTotal){
        state.sampleTotal=sampleTotal;
    },
    SET_SAMPLE(state,sample){
        state.sample=sample;
    },
    
}
const actions={
    fetchSamples({commit},{page}){
        (async ()=>{            
            try {
            const response = await fetch(
              `${baseUrl}/api/v1/sample/query/0`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json;charset=utf-8',
                   Authorization: "Bearer " + auth.state.token,
                 
                },
                body: JSON.stringify({pagesize:state.perPage,page:page})
              }
            );
            const data = await response.json();
            commit('SET_SAMPLES',data);
          } catch (error) {
            console.error(error);
          }})();
       
    },

    fetchSampleCount({commit}){
      (async ()=>{            
          try {
          const response = await fetch(
            `${baseUrl}/api/v1/sample/querycount/0`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
                 Authorization: "Bearer " + auth.state.token,
               
              },
              body:JSON.stringify(null),
            }
          );
          const data = await response.json();          
          commit('SET_SAMPLETOTAL',data.count);
        } catch (error) {
          console.error(error);
        }})();
      
  },
}
export default {
     namespaced: true,
     state,
    // getters,
     actions,
     mutations
}