<template>
<div class="login">
    <h1>广西交航</h1>
   <form  @submit.prevent="login">
  <div class="form-group">
    <label for="name">用户名</label>
    <input id="name" class="form-control" required v-model="username" type="text" placeholder="用户名" aria-describedby="emailHelp" />
    <small id="emailHelp" class="form-text text-muted">统一登录用户名</small>
  </div>
  <div class="form-group">
    <label for="passwd">密码</label>
     <input
       class="form-control"
       id="passwd"
          required
          v-model="password"
          type="password"
          placeholder="Password"
        />
  </div>
  <button type="submit" class="btn btn-primary">登录</button>
</form>


</div>



</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    login: function() {
      const { username, password } = this;
      //this.$router.push("/");
      this.$store
        .dispatch("auth/AUTH_REQUEST", { username, password })
        .then(() => {
          this.$router.push("/");
        });
    },
    logout: function() {
      this.$store.dispatch("auth/AUTH_LOGOUT").then(() => {
        this.$router.push("/login");
      });
    },


    async Notify() {
      const params = {
        id: new Date().valueOf() + "",
        name: "EtOAAssist",
        type: "et",
      };
      const res = await fetch("http://127.0.0.1:58890/askwebnotify", {
        method: "POST",
        body: JSON.stringify(params),
      });
      const json = await res.json();
      console.log("good");
      console.log(json);
    },

    ask(){
      setTimeout(() => {
         this.Notify();
      }, 2000);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login{
  width: 30%;
  margin:auto;
}
h1{
  text-align: center;
  margin-top: 20%;
  margin-bottom: 20%;
}
</style>
