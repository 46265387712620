//import {AUTH_REQUEST,AUTH_SUCESS,AUTH_ERROR,AUTH_LOGOUT}  from "../actions/auth";
//import {USER_REQUEST} from "../actions/user";
import { baseUrl } from "@/config.js";



const state={
    isAuthenticated: sessionStorage.getItem("user") ? true  : false,
    user: sessionStorage.getItem("user") && JSON.parse(sessionStorage.getItem("user")) ,
    token: sessionStorage.getItem("token"),
    refreshtoken: null ,
    status:"login",
};
const getters = {
    isAuthenticated: state=> state.isAuthenticated,
    status : state=>state.status,
    token: state=>state.token
};

const mutations={
    AUTH_REQUEST:state=>{
        state.status = "loading";
        state.isAuthenticated=false;
    },
    AUTH_SUCESS:(state,res)=>{
        state.isAuthenticated=true,
        state.user= res.user,
        state.token= res.access_token,
        state.refreshtoken = res.refreshtoken,
        state.status="success";

    },
    AUTH_ERROR:state=>{
        state.status="error";

    },
    AUTH_LOGOUT:state=>{
        state.isAuthenticated=false,
        state.user= null,
        state.token= null,
        state.token = null,
        state.status="logout";
    }
};


const actions={
    AUTH_REQUEST:({commit},user)=>{
        return new Promise((resolve,reject)=>{
            commit("AUTH_REQUEST");
            fetch(`${baseUrl}/api/v1/user/loginlab`, {
                method: "post",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  username: user.username,
                  password: user.password
                })
              })
            .then(response => {
                if(response.ok) {
                    return response.json();
                }else{
                    throw new Error(response.message);
                }})
            .then(json => {
                commit("AUTH_SUCESS",json);
               // dispatch(USER_REQUEST);
                sessionStorage.setItem("user",JSON.stringify(json.user));
                sessionStorage.setItem("token",json.access_token);
                console.log(json.access_token);
                resolve();
            })
            .catch(error=>{
                reject(error.message);
            })

        });
    }, //[AUTH_LOGOUT]
    AUTH_LOGOUT:({commit})=>{
        return new Promise(resolve=>{
            commit("AUTH_LOGOUT");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("user");
            resolve();
        });
    }
};


export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

