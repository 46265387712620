import { baseUrl } from "@/config.js";
import auth from "./auth";

const state = {
  tasks: [],
  taskTotal: 0,
  task: {},
  perPage: 10,
  page:1,

};

const mutations = {
  ADD_TASKB(state, task) {
    state.tasks.push(task);
  },
  SET_TASKS(state, tasks) {
    state.tasks = tasks;
  },
  SET_TASKTOTAL(state, taskTotal) {
    state.taskTotal = taskTotal;
  },
  SET_TASK(state, task) {
    state.task = task;
  },
};
const actions = {
  fetchTaskCount({ commit }) {
    (async () => {
      try {
        const response = await fetch(`${baseUrl}/api/v1/wpsfile/count`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + auth.state.token,
          },
        });
        const data = await response.json();
        commit("SET_TASKTOTAL", data);
      } catch (error) {
        console.error(error);
      }
    })();
  },

  fetchTasks({ commit }, { page }) {
    (async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/v1/wpsfile/list/${state.perPage}/${page}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + auth.state.token,
            },
          }
        );
        const data = await response.json();
        commit("SET_TASKS", data);
      } catch (error) {
        console.error(error);
      }
    })();
  },

  fetchTaskCountByStatus({ commit }, { status }) {
    (async () => {
      try {
        const response = await fetch(`${baseUrl}/api/v1/task/countbystatus/${status}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + auth.state.token,
          },
        });
        const data = await response.json();
        commit("SET_TASKTOTAL", data);
      } catch (error) {
        console.error(error);
      }
    })();
  },

  fetchTasksByStatus({ commit }, { page,status }) {
    (async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/v1/task/listbystatus/${status}/${state.perPage}/${page}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + auth.state.token,
            },
          }
        );
        const data = await response.json();
        commit("SET_TASKS", data);
      } catch (error) {
        console.error(error);
      }
    })();
  },
};
export default {
  namespaced: true,
  state,
  // getters,
  actions,
  mutations,
};
