<template>
  <router-view />
</template>

<script>
export default {
  name: "App",

};
</script>

<style>
html { font-size: 100% }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}



</style>


/* #wps {
  height: 100vh;
}
#wps #wps-iframe {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  height: 100%;
  /* 防止双击缩放 */
/*:-moz-broken touch-action: manipulation;
} */


